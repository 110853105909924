import { Route, Routes } from "react-router-dom";

import Home1Page from "../app/components/home/index";
import AboutUsPage from "../app/components/misc/about-us";
import ContactPage from "../app/components/misc/contact-us";
import Error404Page from "../app/components/misc/error404";
import ProductPage from "../app/components/misc/product";

function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Home1Page />} />
            <Route path='/index' element={<Home1Page />} />
           
            <Route path='/product' element={<ProductPage />} />
            <Route path='/about-us' element={<AboutUsPage />} />
            {/* <Route path='/faq' element={<FaqPage />} /> */}
            <Route path='/error404' element={<Error404Page />} />
            {/* <Route path='/services/services1' element={<Services1Page />} /> */}
            {/* <Route path='/services/services2' element={<Services2Page />} /> */}
            {/* <Route path='/services/detail' element={<ServicesDetailPage />} /> */}
            {/* <Route path='/blogs/grid' element={<BlogGridPage />} /> */}
            {/* <Route path='/blogs/detail' element={<BlogsDetailPage />} /> */}
            <Route path='/contact-us' element={<ContactPage />} />
            <Route path='*' element={<Error404Page/>} />
            <Route element={<Error404Page/>} />
        </Routes>
    )
}

export default AppRoutes;
import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import SectionCompanyApproach2 from "../../sections/home/index3/section-company-approach2";
import { data } from "../../../globals/data/about-us";
import OurMission from "../../sections/home/index/mission";
import OurTeam from "../../sections/home/index/ourTeam";
import WhyChooseUsAbout from "../../sections/home/index/why-choose-us-about";

export default function AboutUsPage() {
  return (
    <>
      <Banner _data={data.banner} />
      <OurMission />
      {/* <SectionCompanyApproach2 _data={data.approch} /> */}
      <WhyChooseUsAbout _data={data.whyChooseUs} />

      {/* <SectionWhatWeDo1 _data={data.whatwedo} /> */}
      <OurTeam />
      {/* <SectionAchivements1 _data={data.achivements} /> */}
      {/* <SectionTestimonials1 _data={data.testimonials} /> */}
      {/* <SectionBlogs1 _data={data.blogs} /> */}
      {/* <SectionCompanyApproch1 _data={data.approach} /> */}
    </>
  );
}

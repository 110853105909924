import SectionSlider1 from "../../sections/home/index/section-slider1";
import SectionSlider2 from "../../sections/home/index2/section-slider2";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionWhyChooseUs1 from "../../sections/home/index/section-why-choose-us1";
import SectionBooking1 from "../../sections/home/index/section-booking1";
import SectionWhatWeDo2 from "../../sections/home/index3/section-what-we-do2";
import SectionEstimation1 from "../../sections/home/index/section-estimation1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionServices1 from "../../sections/home/index/section-services1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionClientsHome from "../../sections/home/index/section-Clients";
import SectionProjects1 from "../../sections/home/index/section-projects1";
import { data } from "../../../globals/data/home1";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionServices2 from "../../sections/home/index2/section-services2";
import SectionTestimonials2 from "../../sections/home/index2/section-testimonials2";
import SectionServices3 from "../../sections/home/index3/section-services3";
import OurMission from "../../sections/home/index/whoWeAre";
import WhoWeAre from "../../sections/home/index/whoWeAre";

function Home1Page() {
  useEffect(() => {
    loadScript("/assets/js/custom.js", true);
  });

  return (
    <>
      <SectionSlider2 />
      <WhoWeAre _data={data.WhoWeAre} />
      {/* <SectionServices2 _data={data.services1} /> */}
      {/* <SectionWhatWeDo1 _data={data.whatwedo} /> */}
      <SectionWhatWeDo2 _data={data.whatwedo} />
      {/* <SectionCompanyApproch1 _data={data.approach} /> */}
      <SectionServices2 _data={data.services} />
      <SectionClientsHome _data={data.clientSection} />
      {/* <SectionWhyChooseUs1 _data={data.whychooseus} /> */}
      {/* <SectionServices1 _data={data.services} /> */}
      {/* <SectionBooking1 _data={data.booking} /> */}
      {/* <SectionEstimation1 _data={data.estimation} /> */}
      {/* <SectionTestimonials1 _data={data.testimonials} /> */}
      {/* <SectionTestimonials2 _data={data.testimonials1} /> */}

      {/* <SectionServices3 _data={data.services} /> */}
      {/* <SectionBlogs1 _data={data.blogs} /> */}
      {/* <SectionProjects1 _data={data.projects} /> */}
     
    </>
  );
}

export default Home1Page;

import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import SectionCompanyApproach2 from "../../sections/home/index3/section-company-approach2";
import { data } from "../../../globals/data/product";
import OurMission from "../../sections/home/index/mission";
import OurTeam from "../../sections/home/index/ourTeam";
import CustomTable from "../../sections/home/index/customTable";
import WhyChooseUsAbout from "../../sections/home/index/why-choose-us-about";

export default function ProductPage() {
  const headers = ["Row Heading", "Column 1", "Column 2", "Column 3"];

  // Define rows (data)
  const data1 = [
    ["Row 1", "Cell 1.1", "Cell 1.2", "Cell 1.3"],
    ["Row 2", "Cell 2.1", "Cell 2.2", "Cell 2.3"],
    ["Row 3", "Cell 3.1", "Cell 3.2", "Cell 3.3"],
  ];

  const headers2 = ["Product", "V", "Mo.", "Al.", "C.", "Si.", "P.", "S."];

  // Define rows (data)
  const data2 = [
    ["Row 1", "Cell 1.1", "Cell 1.2", "Cell 1.3"],
    ["Row 2", "Cell 2.1", "Cell 2.2", "Cell 2.3"],
    ["Row 3", "Cell 3.1", "Cell 3.2", "Cell 3.3"],
  ];
  return (
    <>
      <Banner _data={data.banner} />

      <div className="container home-client2-outer p-t120">
        {/* <!-- TITLE START--> */}
        <div className="section-head center wt-small-separator-outer">
          <h2 className="wt-title">
            Strengthening Tomorrow with Vanadium Innovation
          </h2>
          <p style={{ paddingTop: "1rem" }}>
            At R R Ferro Alloys, we specialize in the production of high-quality
            vanadium compounds essential for various industrial applications.
          </p>
        </div>
      </div>

      <CustomTable headers={headers} data={data1} serial={1} title={"Ferro Vanadium"}/>
      <CustomTable headers={headers} data={data1} serial={2} title={"Vanadium Pentoxide"}/>
      <CustomTable headers={headers} data={data1} serial={3} title={"Ammonium Metavanadate"}/>
      {/* <SectionAchivements1 _data={data.achivements} /> */}
      {/* <SectionTestimonials1 _data={data.testimonials} /> */}
      {/* <SectionBlogs1 _data={data.blogs} /> */}
      {/* <SectionCompanyApproch1 _data={data.approach} /> */}
    </>
  );
}

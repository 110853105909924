import { NavLink } from "react-router-dom";

export default function SectionClientsHome({ _data }) {
  return (
    <>
      <div className="section-full site-bg-gray p-t90 p-b90  tw-client-logo2-area">
        <div className="container home-client2-outer">
          {/* <!-- TITLE START--> */}
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>{_data.title}</div>
            </div>
            <h2 className="wt-title">{_data.subtitle}</h2>
            {/* <p className="section-head-text">We will patiently introduce our company's products to customers, including product specifications, functions, performance, etc Our expertise and 
commitment to quality have made us a trusted name in the industry. Our mission is to deliver top-tier ferroalloy products that meet the diverse needs of our 
clients. We strive to achieve this through a commitment to quality, innovation, and sustainable practices.  </p> */}
          </div>
          {/* <!-- TITLE END--> */}

          <div className="section-content">
            <div className="owl-carousel home-client-carousel2 owl-btn-vertical-center">
              {_data.clients.map((_object, index) => (
                <div key={index} className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <NavLink to="/about-us">
                        <img src={_object} alt="#" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

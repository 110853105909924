import React from "react";

const CustomTable = ({ headers, data, title, serial }) => {
  return (
    <div style={{marginBottom:"4rem"}} className="section-full  site-bg-white  tw-estimation-area3">
      <div className="container">
      <h3 className="table-title">{serial}. {title}</h3>
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;

import { NavLink } from "react-router-dom";

export default function OurTeam() {
  return (
    <>
      <div className="section-full site-bg-white tw-client-logo2-area">
        <div className="container home-client2-outer">
          {/* <!-- TITLE START--> */}
          <div className="section-head center wt-small-separator-outer">
            
            <h2  className="wt-title">
              Our Team
            </h2>
            <p style={{ paddingTop: "1rem" }}>
              Our company has a group of professional talents and advanced
              domestic production equipment and craft. We also pay great
              attention to the research and development of quality of products
              and the implementation of scientific management. At the same time,
              the quality of the product has strict requirements, gained a good
              reputation from both domestic and international customers. We also
              learn from advanced global operational systems, management
              concepts, and operating mechanisms so as to keep strong
              competitive power and potentiality in the bitter competition of
              the market economy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

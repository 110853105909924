import { NavLink } from "react-router-dom";

export default function OurMission() {
  return (
    <>
      <div className="section-full p-t90  site-bg-white tw-client-logo2-area">
        <div className="container home-client2-outer">
          {/* <!-- TITLE START--> */}
          <div className="section-head center mission-subcontainer">
            <div className="w55">
              <div className="wt-small-separator site-text-primary">
                <div>Our Mission</div>
              </div>
              <h2 className="wt-title">
                Delivering Excellence Through Quality and Innovation
              </h2>
            </div>
            <p className="section-head-text w45">
              We will patiently introduce our company's products to customers,
              including product specifications, functions, performance, etc Our
              expertise and commitment to quality have made us a trusted name in
              the industry. Our mission is to deliver top-tier ferroalloy
              products that meet the diverse needs of our clients. We strive to
              achieve this through a commitment to quality, innovation, and
              sustainable practices.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

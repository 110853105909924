import { NavLink } from "react-router-dom";
import { publicUrlFor, loadScript } from "../../../../globals/constants";
import { useEffect } from "react";

export default function SectionSlider2() {
  useEffect(() => {
    loadScript("/assets/js/slider-home2.js", true);
    loadScript("/assets/js/bg-image-move.js", true);
  });

  return (
    <>
      <div className="twm-slider2-wrap">
        {/* Swiper */}
        <div className="swiper twm-slider2">
          <div className="swiper-wrapper">
            {/*Slide One */}
            <div
              className="swiper-slide bg-cover"
              style={{
                backgroundImage: `url(${publicUrlFor(
                  "/assets/images/main-slider/slider1/slide-1.jpg"
                )})`,
              }}
            >
              <div className="h-banner-wrap">
                <div className="h-banner-left">
                  <div className="h-bnr-top">
                    <h2>
                      <span className="title-outline">Excellence </span>
                      Since 1996
                    </h2>
                    <p>
                      Leading manufacturer of premium Ferro Vanadium with ISO
                      9001:2015 certification, serving industry excellence from
                      Korba, India.
                    </p>
                    {/* <NavLink to="/services/services1" className="h-bnr-btn">
                      View Services
                    </NavLink> */}
                  </div>
                </div>
                <div className="cross-line-box left up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="cross-line-box right up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="circle-left-top zoon-in-out" />
              </div>
            </div>
            {/*Slide Two */}
            <div
              className="swiper-slide bg-cover"
              style={{
                backgroundImage: `url(${publicUrlFor(
                  "/assets/images/main-slider/slider1/slide-2.jpeg"
                )})`,
              }}
            >
              <div className="h-banner-wrap">
                <div className="h-banner-left">
                  <div className="h-bnr-top">
                    <h2>
                      <span className="title-outline">Vanadium </span>
                      Elite
                    </h2>
                    <p>
                      Specialized in high-grade Ferro Vanadium, Vanadium
                      Pentoxide, and Ammonium Metavanadate, powered by advanced
                      technology.
                    </p>
                    {/* <NavLink to="/services/services1" className="h-bnr-btn">
                      View Services
                    </NavLink> */}
                  </div>
                </div>
                <div className="cross-line-box left up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="cross-line-box right up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="circle-left-top zoon-in-out" />
              </div>
            </div>
            {/*Slide Three */}
            <div
              className="swiper-slide bg-cover"
              style={{
                backgroundImage: `url(${publicUrlFor(
                  "/assets/images/main-slider/slider1/slide-3.jpg"
                )})`,
              }}
            >
              <div className="h-banner-wrap">
                <div className="h-banner-left">
                  <div className="h-bnr-top">
                    <h2>
                      <span className="title-outline">Quality</span>
                      First
                    </h2>
                    <p>
                      Delivering excellence through rigorous quality control and
                      innovative R&D to meet global industry standards.
                    </p>
                    {/* <NavLink to="/services/services1" className="h-bnr-btn">
                      View Services
                    </NavLink> */}
                  </div>
                </div>
                <div className="cross-line-box left up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="cross-line-box right up-down">
                  <img
                    src={publicUrlFor(
                      "/assets/images/main-slider/slider1/cross-line-box.png"
                    )}
                    alt="#"
                  />
                </div>
                <div className="circle-left-top zoon-in-out" />
              </div>
            </div>
          </div>
          {/* navigation Numbers */}
          <div className="swiper-pagination" />
          {/* navigation Arrow */}
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </div>
      </div>
    </>
  );
}
